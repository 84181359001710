<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- <this-header /> -->

    <b-overlay
      :show="ShowProduct"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <div class="card p-1">
        <div class="d-flex">
          <div class="XjROLg">
            <skeleton-loader-vue
              v-if="!Product.external_image"
              type="rect"
              :width="400"
              :height="400"
              animation="wave"
            />

            <div class="img-show-first">
              <img :src="selectedImg" alt="img-product" />
            </div>

            <span
              v-for="(item, index) in Product.external_image"
              :key="index"
              class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
              :class="{ active: actv === index }"
              @click="imgShow(item.image), actImg(index)"
            >
              <img :src="item.thumnail" />
            </span>
          </div>

          <div class="layout-right ml-md-2">
            <div class="od-pc-offer-title-contain">
              <div class="title-content">
                <div class="title-first-column">
                  <div class="title-text">
                    <skeleton-loader-vue
                      v-if="!Product.product_name"
                      type="rect"
                      :width="600"
                      :height="20"
                      animation="wave"
                    />
                    {{ Product.product_name }}
                  </div>
                </div>
                <div class="title-first-column">
                  <div class="title-text">
                    <skeleton-loader-vue
                      v-if="!Product.shop_name"
                      type="rect"
                      :width="600"
                      :height="20"
                      animation="wave"
                    />
                    {{ $t("key-166") }}: {{ Product.shop_name }}
                  </div>
                </div>
                <div class="title-second-column">
                  <div class="title-text title-second-text" />
                </div>
              </div>
            </div>

            <div v-if="Product.url" class="mt-1 mb-1">
              <button
                class="btn btn-gradient-warning mr-2"
                @click="openNewTab(Product.url)"
              >
                <i class="fa fa-link" /> {{ $t("key-167") }}
              </button>
              <button
                class="btn btn-gradient-success"
                @click="copyText(Product.url)"
              >
                <i class="fa fa-link" /> {{ $t("key-168") }}
              </button>
            </div>

            <div
              v-if="Product.priceRange && Product.priceRange.length > 0"
              class="od-pc-offer-price-contain"
            >
              <div class="od-pc-offer-price-common">
                <div class="price-content">
                  <div class="price-header">
                    <span class="price-name">{{ $t("price") }}</span
                    ><span class="num-name">.</span
                    ><span class="num-name">จำนวนสินค้า</span>
                  </div>
                  <div class="step-price-wrapper brlr-6">
                    <div
                      v-for="(Price, IndexPrice) in Product.priceRange"
                      :key="IndexPrice"
                      class="step-price-item"
                    >
                      <div class="price-box">
                        <span class="price-unit">¥</span
                        ><span class="price-text">{{ Price[1] }}</span>
                      </div>
                      <br />
                      <div class="price-box">
                        <span class="price-unit2">฿</span
                        ><span class="price-text2">{{
                          Commas(Price[1] * Rate)
                        }}</span>
                      </div>
                      <div class="step-unit-box">
                        <span class="unit-text"
                          >{{
                            IndexPrice !== Product.priceRange.length - 1
                              ? `${Price[0]} - ${
                                  Product.priceRange[IndexPrice + 1][0] - 1
                                }`
                              : `≥${Price[0]}`
                          }}
                          {{ Product.unit }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="od-pc-offer-price-contain">
              <div class="od-pc-offer-price-common">
                <div class="price-content">
                  <div class="price-header">
                    <span class="price-name">{{ $t("price") }}</span>
                    <span class="num-name">.</span>
                  </div>
                  <div class="step-price-wrapper brlr-6">
                    <div class="step-price-item">
                      <div class="price-box">
                        <span class="price-unit">¥</span
                        ><span class="price-text">{{ Product.min_price }}</span>
                        <span class="price-text"> ~ </span>
                        <span class="price-unit">¥</span
                        ><span class="price-text">{{ Product.max_price }}</span>
                      </div>
                      <div class="price-box">
                        <span class="price-unit2">฿</span
                        ><span class="price-text2">{{
                          Commas(Product.min_price * Rate)
                        }}</span>
                        <span class="price-text2"> ~ </span>
                        <span class="price-unit2">฿</span
                        ><span class="price-text2">{{
                          Commas(Product.max_price * Rate)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="od-pc-offer-line">
              <div class="division-line" />
            </div>
            <div v-if="Product.color.title" class="od-pc-offer-price-contain">
              <div class="od-pc-offer-price-common">
                <span
                  v-for="(item, index) in Product.color.list"
                  :key="index"
                  class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                  :class="{ active: actv_color === index }"
                  @click="
                    imgShow(item.image), (actv_color = index), (Color = item.id)
                  "
                >
                  <img v-if="item.image" :src="item.image" :alt="item.image" />
                  <small class="text-dark">{{ item.id }}</small>
                </span>
              </div>
            </div>
            <div class="od-pc-offer-line">
              <div class="division-line" />
            </div>
            <div class="pc-sku-wrapper">
              <div
                style="
                  position: relative;
                  border-bottom: 1px solid rgb(233, 233, 233);
                "
              >
                <div
                  class="sku-module-wrapper sku-scene-wrapper"
                  style="padding-top: 15px; overflow: hidden"
                  :style="{ height: isHided ? 'auto' : '200px' }"
                >
                  <div class="sku-prop-module-name">
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">
                        <!-- จำนวน -->
                      </font>
                    </font>
                  </div>
                  {{ $t("color") }} {{ Color }}
                  <div style="flex: 1 1 0%">
                    <div
                      v-for="(item, index) in Basket.filter(
                        (item) => item.color_id === Color
                      )"
                      id="sku-count-widget-wrapper"
                      :key="index"
                      class="count-widget-wrapper"
                    >
                      <div class="sku-item-wrapper">
                        <div class="sku-item-left">
                          <div class="discountPrice-price">
                            <span class="price-text mr-2">{{
                              item.size_title
                            }}</span>
                            <div class="od-pc-offer-price-common">
                              <img
                                v-if="item.size_img"
                                class="responsive"
                                width="50"
                                height="50"
                                :src="item.size_img"
                                :alt="item.size_img"
                              />
                            </div>
                            <span class="price-unit">¥</span
                            ><span class="price-text mr-2">{{
                              item.price
                            }}</span>
                            <span class="price-unit2">฿</span
                            ><span class="price-text2 mr-2">{{
                              Commas(item.price * Rate)
                            }}</span>
                            <font style="vertical-align: inherit">
                              <font style="vertical-align: inherit" /> </font
                            ><font style="vertical-align: inherit">
                              <font style="vertical-align: inherit">
                                {{ $t("quantity") }}
                              </font>
                            </font>
                          </div>
                          <div class="sku-item-sale-num">
                            <font style="vertical-align: inherit">
                              <font style="vertical-align: inherit">
                                {{ $t("key-169") }} {{ item.quantity }}
                                {{ $t("key-111") }}
                              </font> </font
                            ><font style="vertical-align: inherit">
                              <font style="vertical-align: inherit" /> </font
                            ><font style="vertical-align: inherit">
                              <font style="vertical-align: inherit">
                                {{ $t("key-170") }}
                              </font>
                            </font>
                          </div>
                        </div>
                        <div>
                          <!-- <b-form-spinbutton
                            id="sb-inline"
                            v-model="item.order"
                            inline
                            :max="item.quantity"
                            min="0"
                          /> -->
                          <vue-numeric-input
                            v-model="item.order"
                            :min="0"
                            :max="item.quantity"
                            :step="1"
                          ></vue-numeric-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sku-wrapper-expend-button" />
              </div>
            </div>
            <button class="btn-show-more" @click="isHided = !isHided">
              show more {{ isHided ? "▲" : "▼" }}
            </button>

            <div class="od-pc-offer-line">
              <div class="division-line" />
              {{ $t("key-171") }}
              <div>
                <div>
                  <div
                    v-for="(itemsOrder, color_id) in filteredOrders"
                    :key="color_id"
                  >
                    <h2>{{ color_id }}</h2>
                    <ul>
                      <li v-for="(item, index) in itemsOrder" :key="index">
                        <p v-if="item.size_title">
                          Size: {{ item.size_title }}
                        </p>
                        <p>Price: {{ item.price }}</p>
                        <p>Quantity: {{ item.quantity }}</p>
                        <p>Order: {{ item.order }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="orderSum > 0">
              <div class="division-line" />
              <!-- <span class="price-text">ราคารวม</span>
              <div class="d-flex align-items-center">
                <h3>Total Orders: {{ orderSum }}</h3>
                <h3>Total Price: ¥{{ priceSum }}</h3>
                <h3>Total Price: ฿{{ Commas(priceSum * Rate) }}</h3>
              </div> -->

              <table style="width: 100%">
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>{{ orderSum }} {{ $t("key-111") }}</td>
                    <td>¥{{ priceSum }}</td>
                    <td>฿{{ Commas(priceSum * Rate) }}</td>
                    <td class="text-center">
                      {{ $t("key-171") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-2">
              <button class="btn btn-gradient-primary" @click="AddCart()">
                <i class="fa fa-shopping-cart" /> Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="Product.product_name" class="card p-1">
        <div class="od-pc-offer-title-contain">
          <div class="title-content">
            <div class="title-first-column">
              <div class="title-text">
                {{ $t("productDetails") }}
              </div>
            </div>
            <div class="title-second-column">
              <div class="title-text title-second-text" />
            </div>
          </div>
        </div>
        <p v-html="Product.desc" />
        <!-- <div
          v-for="(item) in Product.external_image"
          :key="item.image"
          class="text-center"
        >
          <img
            :src="item.image"
            alt=""
            class="img-fluid"
          >
        </div> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormSpinbutton,
  BOverlay,
  // BIconController,
} from "bootstrap-vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import SkeletonLoaderVue from "skeleton-loader-vue";
// import ThisHeader from './component/ThisHeader.vue'
import VueNumericInput from "vue-numeric-input";

export default {
  components: {
    BFormSpinbutton,
    SkeletonLoaderVue,
    BOverlay,
    // BIconController,
    // ThisHeader,
    VueNumericInput,
  },
  data() {
    return {
      isHided: false,
      value: 1,
      data: {},
      items: [],
      selectedImg: "",
      actv: 0,
      actv_color: 0,
      ID: this.$route.params.id,
      Product: {
        product_name: "",
        priceRange: [],
      },
      Basket: [],
      url_link: "",
      ShowProduct: false,
      Rate: 0,
      ShowOrder: [],
      Color: "",
    };
  },
  // watch: {
  //   Product: {
  //     handler(newItems) {
  //       console.log('newItems', newItems)
  //       if (newItems.length > 0 && !this.selectedImg) {
  //         this.selectedImg = newItems[0].src
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUserslist()
  //     this.GetData(this.$route.params.id)
  //   }, 10000)
  // },
  computed: {
    filteredOrders() {
      if (this.Product.product_name) {
        const groupedData = {};
        const filteredSizes = this.Basket.filter((size) => size.order > 0);
        filteredSizes.forEach((obj) => {
          const { color_id, ...rest } = obj;
          const order = Number(rest.order);
          if (this.Product.priceRange && this.Product.priceRange.length > 0) {
            // eslint-disable-next-line
            for (const index in this.Product.priceRange) {
              const range = this.Product.priceRange[index];
              const [orderQuantity, orderPrice] = range;
              if (Number(index) !== this.Product.priceRange.length - 1) {
                // eslint-disable-next-line no-unused-vars
                const [nextOrderQua, nextOrderPice] =
                  this.Product.priceRange[Number(index) + 1];
                if (order >= orderQuantity && order < nextOrderQua) {
                  // eslint-disable-next-line no-param-reassign
                  obj.price = orderPrice;
                  rest.price = orderPrice;
                  break;
                }
              } else if (order >= orderQuantity) {
                // eslint-disable-next-line no-param-reassign
                obj.price = orderPrice;
                rest.price = orderPrice;
              } else {
                // eslint-disable-next-line
                obj.price = this.Product.priceRange[0][1];
                // eslint-disable-next-line prefer-destructuring
                rest.price = this.Product.priceRange[0][1];
              }
            }
          }

          if (groupedData[color_id]) {
            groupedData[color_id].push(rest);
          } else {
            groupedData[color_id] = [rest];
          }
        });
        return groupedData;
      }
      return [];
    },
    orderSum() {
      if (this.Product.product_name) {
        let totalOrder = 0;
        this.Basket.forEach((product) => {
          totalOrder += product.order;
        });
        return totalOrder;
      }
      return 0;
    },
    priceSum() {
      if (this.Product.product_name) {
        let totalPrice = 0;
        const filteredSizes = this.Basket.filter((size) => size.order > 0);
        filteredSizes.forEach((product) => {
          totalPrice += product.price * product.order;
        });
        return totalPrice;
      }
      return 0;
    },
  },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    this.GetData(this.ID);
  },
  methods: {
    imgShow(src) {
      if (src) {
        this.selectedImg = src;
      }
    },
    actImg(id) {
      this.actv = id;
    },
    async GetData(ID) {
      this.ShowProduct = true;
      try {
        const obj = {
          provider: "taobao",
          ID,
        };
        const { data: response } = await this.$http.post(
          "/product/search",
          obj
        );
        if (response.status) {
          this.Product = response.data;
          this.selectedImg = this.Product.main_image;
          this.CalStock(this.Product);
          await this.GetRate();
          // this.ShowProduct = false
        } else {
          this.$router.push({ name: "new-pages" });
        }
      } catch (error) {
        console.log(error);
        this.Alert(false, "ไม่พบสินค้า", "");
        this.$router.push({ name: "new-pages" });
      }
    },
    async CalStock(Product) {
      try {
        if (Product.skus) {
          if (Product.color.title) {
            this.Color = Product.color.list[0].id;
          }
          if (Product.skus["0"] && Object.keys(Product.skus).length > 1) {
            // eslint-disable-next-line no-param-reassign
            delete Product.skus["0"];
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(Product.skus)) {
            const obj = {
              product_name: Product.product_name || null,
              shop_name: Product.shop_name || null,
              unit: Product.unit || null,
              url: Product.url || null,
              color_id: "",
              color_title: "",
              color_img: "",
              size_id: "",
              size_title: "",
              size_img: "",
              price: value.price,
              quantity: value.quantity,
              order: 0,
            };
            if (Product.color.title && Product.size.title) {
              // eslint-disable-next-line prefer-destructuring
              obj.color_id = key.split(";")[0];
              const IndexColor = Product.color.list.findIndex(
                (size) => size.id === obj.color_id
              );
              if (IndexColor !== -1) {
                obj.color_title = Product.color.list[IndexColor].title;
                obj.color_img = Product.color.list[IndexColor].image || "";
              }
              // eslint-disable-next-line prefer-destructuring
              obj.size_id = key.split(";")[1];
              const IndexSize = Product.size.list.findIndex(
                (size) => size.id === obj.size_id
              );
              if (IndexSize !== -1) {
                obj.size_title = Product.size.list[IndexSize].title;
                obj.size_img = Product.size.list[IndexSize].image || "";
              }
            } else if (Product.color.title && !Product.size.title) {
              obj.color_id = key;
              const IndexColor = Product.color.list.findIndex(
                (size) => size.id === key
              );
              if (IndexColor !== -1) {
                obj.color_title = Product.color.list[IndexColor].title;
                obj.color_img = Product.color.list[IndexColor].image || "";
              }
            } else if (
              Product.size.title &&
              Object.keys(Product.skus).length === 1
            ) {
              const IndexSize = Product.size.list.findIndex(
                (size) => size.id === key
              );
              if (IndexSize !== -1) {
                obj.size_id = Product.size.list[IndexSize].id;
                obj.size_title = Product.size.list[IndexSize].title;
                obj.size_img = Product.size.list[IndexSize].image || "";
              }
            } else if (Product.size.title) {
              const IndexSize = Product.size.list.findIndex(
                (size) => size.id === key
              );
              if (IndexSize !== -1) {
                obj.size_id = Product.size.list[IndexSize].id;
                obj.size_title = Product.size.list[IndexSize].title;
                obj.size_img = Product.size.list[IndexSize].image || "";
              }
            } else if (Product.color.title) {
              const IndexColor = Product.color.list.findIndex(
                (size) => size.id === key
              );
              if (IndexColor !== -1) {
                obj.color_id = Product.color.list[IndexColor].id;
                obj.color_title = Product.color.list[IndexColor].title;
                obj.color_img = Product.color.list[IndexColor].image || "";
              }
            }
            this.Basket.push(obj);
          }
          this.Basket.sort((a, b) => {
            // eslint-disable-next-line radix
            const numA = parseInt(a.size_title);
            // eslint-disable-next-line radix
            const numB = parseInt(b.size_title);
            return numA - numB;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get("/product/rate");
        if (res.status) {
          this.Rate = res.data.cSelling;
          this.ShowProduct = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    AddCart() {
      const Order = this.Basket.filter((product) => product.order > 0);
      const obj = {
        storename: Order[0].shop_name,
        product_name: Order[0].product_name,
        product_url: Order[0].url,
        product_unit: Order[0].unit,
        price_range: this.Product.priceRange,
        product_img: this.Product.external_image[0].image,
        provider: "tmall",
        order_list: Order,
      };
      this.$http
        .post("/cart/addother", obj)
        .then(() => {
          this.$swal({
            title: "เพิ่มสินค้าลงตะกร้าสำเร็จ",
            text: "คุณต้องการไปที่ตะกร้าสินค้าหรือไม่ ?",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "cart" });
            }
          });
        })
        .catch((error) => {
          console.log(error);
          this.Alert(false, "เพิ่มสินค้าลงตะกร้าไม่สำเร็จ", "");
        });
    },
    async GetProductByUrl() {
      const obj = {
        provider: "",
        id: "",
      };
      const url = new URL(this.url_link);
      const { host, pathname, search } = url;
      if (host && host.length > 0) {
        const index1688 = host.indexOf("1688");
        const indextaobao = host.indexOf("taobao");
        const indextmall = host.indexOf("tmall");
        if (index1688 > -1) {
          obj.provider = "1688";
          if (host === "mindx.1688.com" || host === 'detail.m.1688.com') {
            obj.id = url.searchParams.get("offerId");
          } else {
            const IndexCheck = pathname.indexOf("/offer/");
            if (IndexCheck > -1) {
              const of = new RegExp("/offer/", "g");
              const ht = new RegExp(".html", "g");
              obj.id = pathname.replace(of, "");
              obj.id = obj.id.replace(ht, "");
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = "taobao";
          const IndexCheck = search.indexOf("itemIds");
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get("itemIds");
          } else {
            obj.id = url.searchParams.get("id");
          }
        } else if (indextmall > -1) {
          obj.provider = "tmall";
          obj.id = url.searchParams.get("id");
        }
        if (obj.provider && obj.id) {
          if (this.$route.name !== `product-${obj.provider}`) {
            this.$router.push({
              name: `product-${obj.provider}`,
              params: { id: obj.id },
            });
          } else {
            this.$router.push({
              name: `product-${obj.provider}`,
              params: { id: obj.id },
            });
            window.location.reload();
          }
        } else {
          this.Alert(
            false,
            "ไม่พบข้อมูลสินค้า",
            "กรุณากรอก URL ใหม่ให้ถูกต้อง"
          );
        }
      }
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    copyText(Text) {
      const textField = document.createElement("textarea");
      textField.innerText = Text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.Alert(true, "คัดลอกข้อความสำเร็จ", Text);
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? "success" : "error"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style scoped>
.btn-show-more {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  /* margin-top: -10px; */
  border-radius: 0 0 4px 4px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  line-height: 30px;
  /* margin: 0; */
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  outline: none;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(129, 129, 129, 0.329);
}
</style>
